<template>
    <tr>
        <td>{{ lesson.subjectId }}</td>
        <td> 
            <v-autocomplete
                v-model="lessonSubject"
                :items="subjects"
                item-text="title"
                item-value="id"
                return-object
                outlined
                dense
                hide-details
                @change="updateLesson"
            ></v-autocomplete>
        </td>
        <td>
            <v-autocomplete
                v-model="lessonClass"
                :items="formClasses"
                outlined
                dense
                hide-details
                @change="updateLesson"
            ></v-autocomplete>
        </td>
        <td> 
            <v-icon
                small
                @click="deleteSelectedLesson"
                tabindex="-1"
            >
                mdi-delete
            </v-icon>
        </td>
        <td>            
            <v-progress-circular
                indeterminate
                size="16"
                width="3"
                v-show="saving"
            ></v-progress-circular>
            <v-icon
                small
                color="green" 
                v-show="saved"
                class="mr-2"
            >
                mdi-check-all
            </v-icon>
            <v-icon
                small
                color="red"
                v-show="saveError"
            >
                mdi-alert-circle
            </v-icon>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        lesson: Object,
        lessonClasses: Array,
        lessonSubjects: Array,
    },
    created () {
        this.initialize();
    },
    data: () => ({
        subjects: [],
        formClasses: [],
        lessonSubject: {id: '', title: ''},
        lessonClass: '',
        saving: false,
        saved: false,
        saveError: false,
        postLessonRecord: {
            employee_id : '',
            academic_year_id : '',
            subject_id : '',
            form_class_id : '',
            new_subject_id : '',
            new_class_id : ''
        }
    }),
    computed: {
        ...mapGetters({
            academicYearId : 'admin/getAcademicYearId',
            teacherLessons :  'admin/getTeacherLessons'          
        }),
    },
    watch: {
        lesson: {
            handler(val){
                this.lessonSubject.id = val.subjectId;
                this.lessonSubject.title = val.subject;
                this.lessonClass = val.formClass;
            }
        }
    },
    methods: {
        async initialize(){
            this.subjects = [...this.lessonSubjects];
            this.formClasses = [...this.lessonClasses];
            this.lessonSubject.id = this.lesson.subjectId;
            this.lessonSubject.title = this.lesson.subject;
            this.lessonClass = this.lesson.formClass;
            
            this.postLessonRecord.employee_id = this.lesson.employeeId;
            this.postLessonRecord.academic_year_id = this.academicYearId;
            this.postLessonRecord.subject_id = this.lesson.subjectId;
            this.postLessonRecord.form_class_id = this.lesson.formClass;
            
            //console.log(this.postedLesson);            
        },
        ...mapMutations({
            setPostLesson: 'admin/setPostLesson',
            setDeleteLesson: 'admin/setDeleteLesson',
            setTeacherLessons: 'admin/setTeacherLessons'
        }),
        ...mapActions({
            postLesson: 'admin/postLesson',
            deleteLesson: 'admin/deleteLesson',
            getTeacherLessons: 'admin/getTeacherLessons'
        }),
        updateLesson(){
            //console.log(this.postLessonRecord);
            if(this.postLessonRecord.form_class_id && this.postLessonRecord.subject_id){
                this.submitLesson();                
            }
            else{                
                this.postLessonRecord.form_class_id = this.lessonClass;
                this.postLessonRecord.subject_id =  this.lessonSubject.id;
                if(this.postLessonRecord.form_class_id && this.postLessonRecord.subject_id){
                    this.submitLesson();
                }
            }            
        },
        async submitLesson(){
            this.saved = false;
            this.saveError = false; 
            this.saving = true;
            this.postLessonRecord.new_subject_id = this.lessonSubject.id;
            this.postLessonRecord.new_class_id = this.lessonClass;
            this.setPostLesson(this.postLessonRecord);
            try {
                let response = await this.postLesson();
                if(response.status == 200){
                    this.saving = false;
                    this.saved = true;
                    this.saveError = false;
                    
                }
                else{
                    this.saving = false;
                    this.saved = false;
                    this.saveError = true;
                }
                console.log(response);
            } catch (error) {
                console.log(error);
                this.saving = false;
                this.saved = false;
                this.saveError = true;
            }  
        },
        async deleteSelectedLesson(){            
            if(this.lessonSubject.id && this.lessonClass){
                this.saved = false;
                this.saveError = false;
                this.saving = true;           
                
                let lesson = {
                    employee_id : this.lesson.employeeId,
                    academic_year_id : this.academicYearId,
                    subject_id : this.lessonSubject.id,
                    form_class_id : this.lessonClass
                }
                this.setDeleteLesson(lesson);
                // console.log(this.lessonClass);
                // // console.log(this.teacherLessons);
                // let teacherLessons = [...this.teacherLessons];
                // let lessonClass = this.lessonClass;
                // let subjectId = this.lessonSubjectId;
                // console.log(teacherLessons);
                // let deleted = teacherLessons.filter(function(value){
                //     if(value.formClass != lessonClass && value.subjectId != subjectId){
                //         return value;
                //     }
                // })
                // console.log(deleted);
                
                try {
                    let response = await this.deleteLesson();
                    console.log(response);                
                    this.saving = false;
                    this.saved = false;
                    this.saveError = false;

                    response = await this.getTeacherLessons(this.lesson.employeeId);                
                    let records = response.data;
                    let teacherLessons = [];
                    records.forEach(record => {                     
                        let lesson = {
                            id: record.id,
                            subjectId: record.subject_id,
                            subject: record.subject.title,
                            formClass: record.form_class_id,
                            employeeId: this.lesson.employeeId
                        }
                        teacherLessons.push(lesson);
                    })
                    this.setTeacherLessons(teacherLessons);
                    //this.setTeacherLessons(deleted);
                } catch (error) {
                    console.log(error);
                    this.saving = false;
                    this.saved = false;
                    this.saveError = true;
                }
            }
            else{
                // let deleted = teacherLessons.filter(function(value){
                //     if(value.formClass != lessonClass && value.subjectId != subjectId){
                //         return value;
                //     }
                // })
                // console.log(deleted);
            }
            
        }
    }
}
</script>