<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="dateModel"
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateModel"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                outlined
                style="font-size:0.7rem"
                :label="label"
                :disabled="disabled"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="dateModel"
            scrollable
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modal = false"
            >
                Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveDate"
            >
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
export default {
    props: {
        date: {
            type: String,
            default: null,
        },
        
        label: {
            type: String,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        }
    },   

    watch: {
        date: {
            handler (val) {
                console.log(`Date: ${val}`)
                this.dateModel = val;
            }
        }
    },

    data: () => ({       
        modal: false,
        dateModel: '',
    }),

    methods: {
        saveDate () {
            this.$refs.dialog.save(this.dateModel);
            this.$emit('change-date', this.dateModel);            
        }
    }
}
</script>