<template>
    <tr>
        <td style="text-align:center">            
            <v-select
                v-model="itemModel.subject_id"                
                :items="subjects"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin" 
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.title }}
                </template>
            </v-select>
        </td>

        <td>{{ itemModel.abbr}}</td>

        <td 
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.course_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="disableExamMark" 
            ></v-text-field>
        </td>

        <td 
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.exam_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="disableExamMark"
            ></v-text-field>
        </td> 
       
        <td>            
            <v-select
                v-model="itemModel.coded_comment"                
                :items="codedComments1"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin"  
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>                                   
        </td>

        <td>
            <v-select
                v-model="itemModel.coded_comment_1"                
                :items="codedComments1"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin"  
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>                               
        </td>

        <td>
            <v-btn 
                icon
                @click="deleteRecord"
                :disabled="!admin" 
            >
                <v-icon
                    small
                >
                    mdi-delete
                </v-icon>
            </v-btn>
        </td>        
    </tr>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        item: Object,
        formLevel: Number,
        paper3: Boolean,              
    },
    
    mounted: function () {        
        this.codedComments1 = [...this.codedComments];
        this.codedComments2 = [...this.codedComments];
        this.disableMarks();
        // console.log(this.item);
        // console.log(this.subjectWeightings);
        // console.log(this.paper3);
    },

    data: () => ({
        subject: null,
        codedComments1: [],
        codedComments2: [],
        examMark: true,
    }),

    computed: {
        ...mapGetters({
            subjects: 'admin/getSubjects',
            codedComments: 'termReports/getCodedComments',
            admin: 'auth/getAdmin',            
        }),

        itemModel () {
            return {...this.item};
        },
        
        disablePaper3 () {
            if(!this.admin) return true;
            else if(this.admin && !this.paper3) return true;
            return false;    
        },

        disableExamMark () {
            if(!this.admin) return true;
            if(this.admin && !this.examMark) return true;
            return false;
        }
    },    

    methods: {
        ...mapActions({
            postTable2Record: 'admin/postUpdateTable2',
            getTable2Records: 'termReports/getTable2Records',
        }),

        ...mapMutations({
            setTable2Record: 'admin/setSelectedTable2Record',           
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects',
            setSnackbar: 'admin/setSnackbar',
            setDialogDelete: 'admin/setDialogDelete',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
        }),

        async updateRecord () {
            this.setSaving(true);
            this.setSaveStatus("Updating Record");
            this.setSaved(false);
            this.setSaveError(false);

            let table2Record = Object.assign({}, this.itemModel);
            table2Record.subject_id_old = this.item.subject_id;
            table2Record.subject_id_new = this.itemModel.subject_id;
            this.setTable2Record(table2Record);
            try {
                let response = await this.postTable2Record();
                console.log(response);

                response = await this.getTable2Records();
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);

                this.setSaving(false);
                this.setSaveStatus("Record Updated");
                this.setSaved(true);
                this.setSaveError(false);

                this.setSnackbar({
                    text: `Record Updated`,
                    display: true,
                    color: 'primary',
                }); 

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });
                
                this.setSaving(false);
                this.setSaveStatus("Error Occured!");
                this.setSaved(false);
                this.setSaveError(true);
            }

            // this.overlay(false);
            // this.loading(false);
        },

        disableMarks () {
            console.log(this.item.term);
            console.log(this.formLevel);
            if(this.item.term == 2 && this.formLevel < 5){
                this.examMark = false;
            }
            console.log(this.examMark);
        },

        deleteRecord () {                      
            this.setDialogDelete({
                display: true,
                text: `Delete ${this.itemModel.title} ?`,
                cancel: true,
                deleteSubject: true,
                deleteStudent: false,
                ok: false
            })            
            this.setTable2Record(this.item);            
        },
        
    }
}
</script>

<style scoped>
    ::v-deep .v-input{
        width: 60px;
    }    

    ::v-deep .v-select__slot{
        height: 20px;
    }

    ::v-deep .v-input__icon{
        height: 5px;
        min-width: 8px;
        width: 8px;
    }

    ::v-deep .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections{
        height: 20px;
        padding: 0px;
    }

    ::v-deep .v-input input{
        max-height: 20px;
    }

    ::v-deep .v-btn--icon.v-size--default{
        height: 20px;
        width: 20px;
    }

    
</style>